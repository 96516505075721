// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mb-kids-js": () => import("./../../../src/pages/mb-kids.js" /* webpackChunkName: "component---src-pages-mb-kids-js" */),
  "component---src-pages-mb-young-adults-js": () => import("./../../../src/pages/mb-young-adults.js" /* webpackChunkName: "component---src-pages-mb-young-adults-js" */),
  "component---src-pages-mb-youth-js": () => import("./../../../src/pages/mb-youth.js" /* webpackChunkName: "component---src-pages-mb-youth-js" */),
  "component---src-pages-ministries-js": () => import("./../../../src/pages/ministries.js" /* webpackChunkName: "component---src-pages-ministries-js" */),
  "component---src-pages-musicplay-js": () => import("./../../../src/pages/musicplay.js" /* webpackChunkName: "component---src-pages-musicplay-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

